import { graphql } from "gatsby"
import BlogComponent from "../components/blog"

export default BlogComponent

export const query = graphql`
  query($formatString: String!) {
    allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {date: {ne: null}}}) {
      nodes {
        childrenMdxPost {
          slug
        }
        excerpt
        timeToRead
        frontmatter {
          date(formatString: $formatString)
          tags
          title
          recipe {
            title
            ingredients {
              name
              percentage
            }
          }
          banner {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
